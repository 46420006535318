/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faVenusMars, } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyD3D_Q103AyZmSy5UiLfWoD1nBmhXHnKAI',
		libraries: 'places', 
	},
})
//Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
library.add(faUserSecret, faVenusMars)
Vue.component('font-awesome-icon', FontAwesomeIcon)


// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)

/* Lightbox */
import Lightbox from 'vue-easy-lightbox'
Vue.use(Lightbox)

//google analyst
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "UA-3761402-36" }
});

// VV Tour 
import VueTour from 'vue-tour';

Vue.use(VueTour)
// Editor
import 'vue-tour/dist/vue-tour.css' 
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

/* SVG map */
import 'vue-svg-map/dist/index.css'

// // axios
// import axios from "./axios.js"
// Vue.prototype.$http = axios

// Theme Configurations
import '../themeConfig.js'


import './filters/translate.filter';


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'
// Vuexy Admin Filters
import './filters/filters'

if(localStorage.getItem('VVtoken')) {
  let VVtoken = localStorage.getItem("VVtoken");
  let token = JSON.parse(VVtoken);
  store.dispatch('updateAuthToken', token)
}

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// VeeValidate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')

// Common style
import '@/assets/scss/style.scss'
import '@/assets/scss/responsive.css'

//use axios
import './axios/'

//Mixin
import "./mixins/mixin";
import titleMixin from './mixins/titleMixin'
Vue.mixin(titleMixin)

// import JQuery from 'jquery'
// window.jQuery = JQuery



Vue.config.productionTip = false

new Vue({
    router,
    store,
    
    render: h => h(App)
}).$mount('#app')
