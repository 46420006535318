/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList"
import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"


// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////

const userDefaults = {
  uid: 0,          // From Auth
  displayName: "John Doe", // From Auth
  about: "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
  photoURL: require("@/assets/images/generic-user-icon.png"), // From Auth
  status: "online",
  userRole: "admin"
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

let fromDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
let fromDay = fromDate.getDate();
let fromMonth = fromDate.getMonth() + 1;
let fromYear = fromDate.getFullYear();

let toDate = new Date();
let toDay = toDate.getDate();
let toMonth = toDate.getMonth() + 1;
let toYear = toDate.getFullYear();

const state = {
  AppActiveUser: userDefaults,
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  mainLayoutType: themeConfig.mainLayoutType || "vertical",
  navbarSearchAndPinList: navbarSearchAndPinList,
  reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: "default",
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  starredPages: navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
  theme: themeConfig.theme || "light",
  themePrimaryColor: colors.primary,
  themeFirstColor: "#3dc9b3",
  themeSecondColor:"#3f51b5",
  themeThirdColor: "#03a9f4",
  themeFourthColor: "#e91e63",

  themeactionbutton:"#044d81",
  
  themechart1:"#044d80",
  themechart2:"#ecb940",
  themechart3:"#7ac546",
  themechart4:"#cdcdcd",
  themechart5:"#5e4d80",
  themechart6:"#ffde90",
  themechart7:"#5f973a",
  themechart8:"#989898",
  

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
  authTokenVV: null,
  filterFromDate: fromMonth+'/'+fromDay+'/'+fromYear,
  filterToDate: toMonth+'/'+toDay+'/'+toYear,

  isReseller: null,
  maxReviewLocations: 0,
  maxTollFreeNumber: 0,
  maxUsers: 0,
  enableZapierIntegration: null,
  loggedInAccountLogo: null,
  actualDomainName:null,

  userDetails: null,
  showCallTrackingBillingSummaryForResellers: null,
  navbarDynamicColor:"#ffffff",

  companyAccountId:null,

  appVersion: null
}

export default state
