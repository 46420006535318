/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const actions = {

    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },
    updateNavbarColor({ commit }, val) {
      commit('UPDATE_NAVBAR_COLOR', val)
    },
    updateChartColor1({ commit }, val) {
      commit('UPDATE_CHART1_COLOR', val)
    },
    updateChartColor2({ commit }, val) {
      commit('UPDATE_CHART2_COLOR', val)
    },
    updateChartColor3({ commit }, val) {
      commit('UPDATE_CHART3_COLOR', val)
    },
    updateChartColor4({ commit }, val) {
      commit('UPDATE_CHART4_COLOR', val)
    },
    updateChartColor5({ commit }, val) {
      commit('UPDATE_CHART5_COLOR', val)
    },
    updateChartColor6({ commit }, val) {
      commit('UPDATE_CHART6_COLOR', val)
    },
    updateChartColor7({ commit }, val) {
      commit('UPDATE_CHART7_COLOR', val)
    },
    updateChartColor8({ commit }, val) {
      commit('UPDATE_CHART8_COLOR', val)
    },
    updateactionButtonColor({ commit }, val) {
      commit('UPDATE_ACTION_BUTTON_COLOR', val)
    },
    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },

    updateAuthToken({ commit }, val) {
      commit('UPDATE_AUTH_TOKEN', val);
    },
    startDateChange({ commit }, val) {
      commit('UPDATE_START_DATE', val);
    },
    endDateChange({ commit }, val) {
      commit('UPDATE_END_DATE', val);
    },
}

export default actions
