import Vue from 'vue'
import store from '../store/store'
import Cookie from "js-cookie";
import LabelConstant from '../constants/label-constants.js'

let str = window.location.href.split("/")[window.location.href.split("/").length - 1]

if(!localStorage.getItem("VendastaVVAccountId") && !localStorage.getItem("VVtoken") && str.length == 13){
    localStorage.setItem("VendastaVVAccountId", JSON.stringify(window.location.href.split("/")[window.location.href.split("/").length - 1]));
}

const myMixin = Vue.mixin({
    data() {
        return {
            button_loading: false,
            LabelConstant,
            globalVendastaAccountId: null,
        }
    },
    created() {
        if (this.$store) {
            if (this.$store.state.authTokenVV) {
                let uid = this.$route.params.id
                if (uid) {
                    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.authTokenVV[uid].token;
                }
            }
        }
        if(localStorage.getItem("VVtoken")){
            let VVtoken = localStorage.getItem("VVtoken");
            let token = JSON.parse(VVtoken);
            this.globalVendastaAccountId = token[0].vendastaAccountId
        }
    },
    mounted() {
        //  this.setCallbacksTour();
    },
    filters: {
        tableRecordNumberFormatter: function(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        addSpaceAfterComma: function(value) {
            return value.replace(/,/g, ", ")
        },
        decimalsTwoDigitConversion: function(value) {
            if (value > 0 || value < 0) {
                return value.toFixed(2)
            } else {
                return value
            }
        }
    },
    methods: {
        setVVGuidedTourCookie() {
            Cookie.set("VVGuidedTour", false);
        },
        isLoggedIn() {
            if (localStorage.getItem('VVtoken')) {
                return true
            } else {
                this.logout()
            }
        },
        logout() {
            // localStorage.removeItem('VVtoken')
            // location.reload();
            let uid = this.$route.params.id
            if (uid == 0) {
                Cookie.remove("VVtokenGeneratedAt")
                localStorage.removeItem('VVtoken')
                localStorage.removeItem('isCookieConsent')
            } else {
                let VVtoken = localStorage.getItem("VVtoken");
                let token = JSON.parse(VVtoken);

                if (localStorage.getItem('VVtoken')) {
                    // this.$router.push('/pages/login');
                    this.$router.push({
                        name: 'page-login',
                        params: { reload: true }
                    });
                }

                if (uid < token.length) {
                    token[uid].token = null
                    token[uid].isLogedIn = false
                    token[uid].menuItems = null
                    token[uid].themeDetail = null
                    token[uid].isReseller = null
                    token[uid].isActiveFreeTrial = null
                    token[uid].isExpiredFreeTrial = null
                    token[uid].ShowContactReport = null
                    token[uid].userDetails = null
                    token[uid].maxLocalNumbers = null

                    localStorage.setItem("VVtoken", JSON.stringify(token));
                    store.dispatch('updateAuthToken', token)
                }

                // window.close();

                return false
            }

            this.$router.push({
                name: 'page-login',
                params: { reload: true }
            });
        },
        button_loader(val, container) {
            this.button_loading = val
            if (val) {
                this.$vs.loading({
                    background: 'primary',
                    color: '#fff',
                    container: container,
                    scale: 0.45
                })
            } else {
                this.$vs.loading.close(container + ' > .con-vs-loading')
            }
        },
        showError(e, button_loader = null) {
            if (e.response && e.response.status === 401) {
                this.logout()
            }

            button_loader ? this.button_loader(false, button_loader) : this.$vs.loading.close();
            if (!e.response || !e.response.data || !e.response.data.error) {
                return
            }
            let error = e.response.data.error;
            this.$vs.notify({
                text: error,
                color: "danger",
                position: "top-right"
            });

        },
        /*
        Format numeric value
        e.g.: 99,999
        */
        numberFormatter(value) {
            if (value) {
                if (value == 'NaN' || value == 'Infinity') {
                    return '--'
                } else {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            } else {
                return value
            }
        },
        //Set From Date
        setFromDate() {
            /*let data = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
            let day = data.getDate();
            let month = data.getMonth()+1;
            return this.$store.state.filterFromDate;
            let year = data.getFullYear();*/
            this.fromDate = this.$store.state.filterFromDate;
        },
        //Set To Date
        setToDate() {
            /*  let data = new Date()
              let day = data.getDate();
              let month = data.getMonth()+1;
              let year = data.getFullYear();
              return this.$store.state.filterToDate;*/
            this.toDate = this.$store.state.filterToDate;
        },
        //Sort table data in Ascending and Descending order
        GetSortOrder(prop, sortType) {
            return function(a, b) {
                if (sortType == 'DESC') {
                    if (a[prop] < b[prop]) {
                        return 1;
                    } else if (a[prop] > b[prop]) {
                        return -1;
                    }
                } else {
                    if (a[prop] > b[prop]) {
                        return 1;
                    } else if (a[prop] < b[prop]) {
                        return -1;
                    }
                }
                return 0;
            }
        },

        /*
            Convert number in to time format
            e.g.: 181 -> 3:01
        */
        timeConvert(value) {
            if (value) {
                let num = value.toString().split(".")[0];
                let hours = Math.floor(num / 60);
                let minutes = num % 60;

                let minutesStr = minutes.toString()

                if (minutesStr.length >= 2) {
                    return `${hours}:${minutesStr}`;
                } else {
                    return `${hours}:0${minutesStr}`;
                }
            } else {
                return '0:00'
            }
        },
        //Format percent value
        percentFormatter(value) {
            if (value) {
                if (value == 'NaN' || value == 'Infinity') {
                    return 0
                } else {
                    return value
                }
            } else {
                if (isNaN(value) || !isFinite(value)) {
                    return 0
                } else {
                    return value
                }
            }
        },

        /*
            Format value in currency
            e.g.: 99,999.00
        */
        currencyFormatter(value) {
            if (value) {
                if (value == 'NaN' || value == 'Infinity') {
                    return '--'
                } else {
                    var currencyFormattedValue = parseFloat(value).toFixed(2);
                    return currencyFormattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            } else {
                return value
            }
        },

        //Get menu item and redirect user or brand to default url based on menu access permission.
        getRedirectDefaultUrl(menuItem) {
            if (menuItem == 'dashboard-dashboard') {
                return '/dashboard/uid/';
            } else if (menuItem == 'reviews-reviewdashboard') {
                return '/reputation-management/review-dashboard/uid/';
            } else if (menuItem == 'calltracking-calldashboard') {
                return '/call-tracking/call-dashboard/uid/';
            } else if (menuItem == 'dashboard-consumerdashboard') {
                return '/consumer/dashboard/uid/';
            }
            // for(var i = 0; menuItem.length > i; i++){
            //     if(menuItem[i].MenuItemName == 'dashboard-dashboard' && menuItem[i].Visible == true){
            //         return '/dashboard/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'leads-searchleads' && menuItem[i].Visible == true){
            //         return '/leads/search-lead/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'leads-formcaptures' && menuItem[i].Visible == true){
            //         return '/leads/form-captures/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'leads-emailcampaigns' && menuItem[i].Visible == true){
            //         return '/leads/email-campaigns/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'leads-reporting' && menuItem[i].Visible == true){
            //         return '/leads/reporting/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'calltracking-gettingstarted' && menuItem[i].Visible == true){
            //         return '/call-tracking/getting-started/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'calltracking-calldashboard' && menuItem[i].Visible == true){
            //         return '/call-tracking/call-dashboard/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'calltracking-textmessageinbox' && menuItem[i].Visible == true){
            //         return '/call-tracking/text-msg-inbox/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'calltracking-numbers' && menuItem[i].Visible == true){
            //         return '/call-tracking/numbers/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'calltracking-Integration' && menuItem[i].Visible == true){
            //         return '/call-tracking/integration/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'calltracking-reports' && menuItem[i].Visible == true){
            //         return '/call-tracking/reports/uid/';
            //     }		
            //     if(menuItem[i].MenuItemName == 'reviews-gettingstarted' && menuItem[i].Visible == true){
            //         return '/reputation-management/getting-started/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'reviews-reviewdashboard' && menuItem[i].Visible == true){
            //         return '/reputation-management/review-dashboard/uid/';
            //     }	
            //     if(menuItem[i].MenuItemName == 'reviews-reviewdetails' && menuItem[i].Visible == true){
            //         return '/reputation-management/review-details/uid/';
            //     }	
            //     if(menuItem[i].MenuItemName == 'reviews-gatherreviews' && menuItem[i].Visible == true){
            //         return '/reputation-management/gather-reviews/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'reviews-landingpages' && menuItem[i].Visible == true){
            //         return '/reputation-management/landing-pages/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'reviews-widgets' && menuItem[i].Visible == true){
            //         return '/reputation-management/widgets/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'clientemail-gettingstarted' && menuItem[i].Visible == true){
            //         return '/client-email/getting-started/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'clientemail-emaildashboard' && menuItem[i].Visible == true){
            //         return '/client-email/email-dashboard/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'clientemail-activity' && menuItem[i].Visible == true){
            //         return '/client-email/activity/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'clientemail-templates' && menuItem[i].Visible == true){
            //         return '/client-email/templates/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'analytics-overview' && menuItem[i].Visible == true){
            //         return '/analytics/overview/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'analytics-location' && menuItem[i].Visible == true){
            //         return '/analytics/location/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'analytics-device' && menuItem[i].Visible == true){
            //         return '/analytics/device/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'analytics-engagement' && menuItem[i].Visible == true){
            //         return '/analytics/engagement/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'analytics-referrer' && menuItem[i].Visible == true){
            //         return '/analytics/referrer/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'analytics-entrypages' && menuItem[i].Visible == true){
            //         return '/analytics/entry/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'analytics-exitpages' && menuItem[i].Visible == true){
            //         return '/analytics/exit/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'leadhandling-notificationrules' && menuItem[i].Visible == true){
            //         return '/lead-handling/notification-rules/uid/';
            //     }	
            //     if(menuItem[i].MenuItemName == 'leadhandling-territoryrules' && menuItem[i].Visible == true){
            //         return '/lead-handling/territory-rules/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'leadhandling-watchexcludelist' && menuItem[i].Visible == true){
            //         return '/lead-handling/watch-exclude-rules/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'myaccount-myinformation' && menuItem[i].Visible == true){
            //         return '/my-account/my-information/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'myaccount-changepassword' && menuItem[i].Visible == true){
            //         return '/my-account/change-password/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'myaccount-crmintegration' && menuItem[i].Visible == true){
            //         return '/my-account/crm-integration/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'billing-billinginfo' && menuItem[i].Visible == true){
            //         return '/billing/billing-info/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'billing-invoices' && menuItem[i].Visible == true){
            //         return '/billing/invoice/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'billing-auditlog' && menuItem[i].Visible == true){
            //         return '/billing/audit-log/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'configuration-users' && menuItem[i].Visible == true){
            //         return '/configuration/user/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'configuration-domains' && menuItem[i].Visible == true){
            //         return '/configuration/Domains/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'configuration-installation' && menuItem[i].Visible == true){
            //         return '/configuration/installation/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'configuration-excludeip' && menuItem[i].Visible == true){
            //         return '/configuration/excludedip/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'configuration-emailcampaigns' && menuItem[i].Visible == true){
            //         return '/configuration/emailcampaigns/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'configuration-formscapture' && menuItem[i].Visible == true){
            //         return '/configuration/formscapture/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'configuration-filtercompanies' && menuItem[i].Visible == true){
            //         return '/configuration/filtercompanies/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'configuration-filtercountries' && menuItem[i].Visible == true){
            //         return '/configuration/filtercountries/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'configuration-filtercontacts' && menuItem[i].Visible == true){
            //         return '/configuration/filterjobtitle/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'reseller-customers' && menuItem[i].Visible == true){
            //         return '/reseller/customers/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'reseller-resellersettings' && menuItem[i].Visible == true){
            //         return '/reseller/reseller-setting/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'reseller-manageresellers' && menuItem[i].Visible == true){
            //         return '/reseller/reseller-manage/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'reseller-resellerreports' && menuItem[i].Visible == true){
            //         return '/reseller/reseller-report/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'reseller-billingmap' && menuItem[i].Visible == true){
            //         return '/reseller/reseller-billing-map/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'signup-signup' && menuItem[i].Visible == true){
            //         return '/sign-up/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'dashboard-gmbinsights' && menuItem[i].Visible == true){
            //         return '/create-dashboard/gmb-insight/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'dashboard-ppcadwords' && menuItem[i].Visible == true){
            //         return '/create-dashboard/ppc-adwords/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'dashboard-facebookorganic' && menuItem[i].Visible == true){
            //         return '/create-dashboard/facebook-organic/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'dashboard-facebookpaid' && menuItem[i].Visible == true){
            //         return '/create-dashboard/facebook-paid/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'dashboard-seo' && menuItem[i].Visible == true){
            //         return '/create-dashboard/seo/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'dashboard-programmatic' && menuItem[i].Visible == true){
            //         return '/create-dashboard/programmatic/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'dashboard-ppcbing' && menuItem[i].Visible == true){
            //         return '/create-dashboard/ppcbing/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'socialautomation-socialcalendar' && menuItem[i].Visible == true){
            //         return '/social-automation/social-calendar/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'socialautomation-yourconnections' && menuItem[i].Visible == true){
            //         return '/social-automation/your-connection/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'socialautomation-filemanager' && menuItem[i].Visible == true){
            //         return '/social-automation/file-manager/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'socialautomation-watermark' && menuItem[i].Visible == true){
            //         return '/social-automation/watermark/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'socialautomation-caption' && menuItem[i].Visible == true){
            //         return '/social-automation/caption/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'socialautomation-recentpost' && menuItem[i].Visible == true){
            //         return '/social-automation/queue/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'socialautomation-queue' && menuItem[i].Visible == true){
            //         return '/social-automation/recent-post/uid/';
            //     }
            //     if(menuItem[i].MenuItemName == 'gmbmanagement-localrankingx' && menuItem[i].Visible == true){
            //         return '/gmb-management/local-ranking/uid/';
            //     }	
            // }
        },
        hexToRGB(h) {
            let r = 0,
                g = 0,
                b = 0;

            // 3 digits
            if (h.length == 4) {
                r = "0x" + h[1] + h[1];
                g = "0x" + h[2] + h[2];
                b = "0x" + h[3] + h[3];

                // 6 digits
            } else if (h.length == 7) {
                r = "0x" + h[1] + h[2];
                g = "0x" + h[3] + h[4];
                b = "0x" + h[5] + h[6];
            }

            return "rgb(" + +r + "," + +g + "," + +b + ")";
        },
        RGBToHex(rgb) {
            // Choose correct separator
            let sep = rgb.indexOf(",") > -1 ? "," : " ";
            // Turn "rgb(r,g,b)" into [r,g,b]
            rgb = rgb
                .substr(4)
                .split(")")[0]
                .split(sep);
            let r = (+rgb[0]).toString(16),
                g = (+rgb[1]).toString(16),
                b = (+rgb[2]).toString(16);
            if (r.length == 1) r = "0" + r;
            if (g.length == 1) g = "0" + g;
            if (b.length == 1) b = "0" + b;
            return "#" + r + g + b;
        },
    },
})
export default myMixin