export default {
    // common labels
    'buttonLabelPurchase': 'Purchase',
    'buttonLabelRemove': 'Remove',
    'buttonLabelPurchaseAdditionalCampaigns': 'Purchase Additional Campaigns',
    'buttonLabelNew': 'New',
    'buttonLabelDownload': 'Download',
    'buttonLabelAddJob': 'Add Job',
    'buttonLabelExport': 'Export',
    'buttonLabelExportCSV': 'Selected to CSV',
    'buttonLabelExportExcel': 'Selected to Excel',
    'buttonLabelExportCRM': 'Selected to CRM',
    'buttonLabelExportQueryResultToCSV': 'Query Result to CSV',
    'buttonLabelUpdateDashboard': 'Update Dashboard',
    'buttonLabelSearch': 'Search',
    'buttonLabelCancel': 'Cancel',
    'buttonLabelClose': 'Close',
    'buttonLabelOkay': 'Okay',
    'buttonLabelYes': 'Yes',
    'buttonLabelNo': 'No',
    'buttonLabelAdd': 'Add',
    'buttonLabelDelete': 'Delete',
    'buttonLabelSave': 'Save',
    'buttonLabelView': 'View',
    'buttonLabelUpdate': 'Update',
    'buttonLabelReset': "Reset",
    'buttonLabelOk': 'Ok',
    'buttonLabelCancelMyChange': 'Cancel My Change',
    'buttonLabelViewInformation': 'View Information',
    'buttonLabelRunQuery': 'Run Query',
    'buttonLabelRefresh': 'Refresh',
    'buttonLabelSkip': 'Skip',

    //button common Title
    'buttonTitleEdit': 'Edit',

    //tabel Headers Common 
    'tableHeaderLabelOptions': 'Options',
    'tableHeaderLabelAccountID': 'Account ID',
    'tableHeaderLabelFreeTrialExp': 'Free Trial Exp',
    'tableHeaderLabelPlanType': 'Plan Type',
    'tableHeaderLabelEmailName': 'Email Name',
    'tableHeaderLabelSendAfter': 'Send After',
    'tableHeaderLabelSendTime': 'Send Time',
    'tableHeaderLabelSegmentName': 'Segment Name',
    'tableHeaderLabelMatchType': 'Match Type',
    'tableHeaderLabelFreeTrials': 'Free Trials',
    'tableHeaderLabelPaid': 'Paid',
    'tableHeaderLabelDisplayName': 'Display Name',
    'tableHeaderLabelResellerName': 'Reseller Name',
    'tableHeaderLabelCustomerName': 'Customer Name',
    'tableHeaderLabelDateFirstTraffic': 'Date First Traffic',
    'tableHeaderLabelUsers': 'Users',
    'tableHeaderLabelUser': 'User',
    'tableHeaderLabelPurchasedUsers': 'Purchased Users',
    'tableHeaderLabelNotifiations': 'Notifiations',
    'tableHeaderLabelPageViews': 'Page Views',
    'tableHeaderLabelAccountId': 'Account Id',
    'tableHeaderLabelTagName': 'Tag Name',
    'tableHeaderLabelRecordCall': 'Record Call',
    'tableHeaderLabelForwardCallTo': 'Forward Call To',
    'tableHeaderLabelTrackingNumber': 'Tracking Number',
    'tableHeaderLabelNotes': 'Notes',
    'tableHeaderLabelAmount': 'Amount',

    // Data Enrichment
    'tableHeaderLabelStatus': 'Status',
    'tableHeaderLabelJobName': 'Job Name',
    'tableHeaderLabelJobType': 'Job Type',
    'tableHeaderLabelCreditsUsed': 'Credits Used',
    'tableHeaderLabelContactsUploaded': 'Contacts Uploaded',
    'tableHeaderLabelContactsFound': 'Contacts Found',
    'tableHeaderLabelCreatedBy': 'Created By',
    'tableHeaderLabelDateCreated': 'Date Created',

    // search Lead
    'tableHeaderLabelCountry': 'Country',
    'tableHeaderLabelState': 'State',
    'tableHeaderLabelCity': 'City',
    'tableHeaderLabelKeywords': 'Keywords',
    'tableHeaderLabelType': 'Type',
    'tableHeaderLabelSite': 'Site',
    'tableHeaderLabelCampaignName': 'Campaign Name',
    'tableHeaderLabelCampaignMedium': 'Campaign Medium',
    'tableHeaderLabelCampaignTerm': 'Campaign Term',
    'tableHeaderLabelCampaignContent': 'Campaign Content',
    'tableHeaderLabelCampaignSource': 'Campaign Source',
    'tableHeaderLabelIPAddress': 'IP Address',
    'tableHeaderLabelName': 'Name',
    'tableHeaderLabelVisited': 'Visited',

    // find anyone (who to contact?)
    'tableHeaderLabelLogo': 'Logo',
    'tableHeaderLabelFullName': 'Full Name',
    'tableHeaderLabelLinkedIn': 'LinkedIn',
    'tableHeaderLabelTitle': 'Title',
    'tableHeaderLabelCompany': 'Company',
    'tableHeaderLabelSaved': 'Saved',
    'tableHeaderLabelPhone': 'Phone',
    'tableHeaderLabelSavedFrom': 'Saved From',

    // Who's shoppting -> lead
    'buttonLabelAddtoMySavedContacts': 'Add To My Saved Contacts',

    // who's shopping -> campaign 
    'buttonLabelBack': 'Back',
    'buttonLabelNext': 'Next',
    'buttonLabelFinish': 'Finish',
    'buttonLabelRequestNewKeyword': 'Request New Keyword',
    'buttonLabelRequestNewCompetitor': 'Request New Competitor',
    'buttonLabelAddCampaign': 'Add Campaign',
    'buttonLabelAddCategory': 'Add Category',
    'buttonLabelRequestNewCategory': 'Request New Category',

    //email-marketing -> Dashboard
    'buttonLabelAddaTag': 'Add a tag',
    'buttonLabelViewEmail': 'View Email',
    'buttonLabelShowMore': 'Show More',
    'buttonLabelShowLess': 'Show Less',
    'buttonLabelExportContact': 'Export Contact',
    'buttonLabelViewReport': 'View Report',
    'linkLabelViewAll': "View All",

    //email-marketing -> Settings
    'buttonLabelSaveCampaignDefaults': 'Save Campaign Defaults',
    'buttonLabelAddaField': 'Add a Field',
    'buttonLabelAddSender': 'Add Sender',
    'buttonLabelSaveNotification': 'Save Notification',
    'buttonLabelStartNewImport': 'Start New Import',
    'buttonLabelSaveSender': 'Save Sender',
    'buttonLabelUpdateSender': 'Update Sender',

    //email-marketing -> campaigns
    'buttonLabelCreateCampaign': 'Create Campaign',
    'buttonLabelAddFolder': 'Add Folder',
    'buttonLabelCreateEmailTemplate': 'Create Email Template',
    'buttonLabelSeletScheduleTime': 'Select Schedule Time',
    'buttonLabelSaveFolder': 'Save Folder',
    'buttonLabelCopy': 'Copy',
    'buttonLabelRename': 'Rename',
    'buttonLabelEmailDesigner': 'Email Designer',
    'buttonLabelSend': 'Send',
    'linkLabelSavechangesAndWorkOnItLater': 'Save changes and work on it later',

    //email-marketing -> contacts 
    'buttonLabelAddSegment': 'Add Segment',
    'buttonLabelImportContacts': 'Import Contacts',
    'buttonLabelAddContact': 'Add Contact',
    'buttonLabelEditContact': 'Edit Contact',
    'buttonLabelAddTag': 'Add Tag',
    'buttonLabelAddNote': 'Add Note',
    'buttonLabelSendCampaign': 'Send Campaign',
    'buttonLabelEditSegment': 'Edit Segment',
    'buttonLabelExportContacts': 'Export Contacts',


    //configuration -> my customers
    'buttonLabelImportCustomers': 'Import Customers',
    'buttonLabelSaveCustomers': 'Save Customers',

    //heading label
    'headingLabelTellusAboutYourCustomers': 'Tell us about your customers',
    'popupHeadingLabelDeleteCustomer': 'Delete Customer?',

    //My customers information
    'infoLabelMyCustomers': ' By telling us about your customers, we can customize your experience you receive from our platform. You will receive valuable reporting as well as integration into our various components of our platform. This will assist with understanding the interactions of current customers vs. new prospects.',

    // my customers Table Headers
    'tableHeaderLabelDomainName': 'Domain Name',
    'tableHeaderLabelCompanyName': 'Company Name',
    'tableHeaderLabelDate': 'Date',

    //input label
    'inputLabelInfo': ' Please enter one domain name per line. Maximum of 10,000 entries per upload.',
    'inputPlaceHolderDomainName': 'Enter domain names, one per line',

    'inputLabelDomainName': 'Domain Name',

    //configuration -> my competitors
    'buttonLabelImportCompetitors': 'Import Competitors',
    'buttonLabelSaveCompetitors': 'Save Competitor',

    //heading label
    'headingLabelTellusAboutYourCompetitors': 'Tell us about your competitors',
    'popupHeadingLabelDeleteCompetitor': 'Delete Competitor?',

    //My customers information
    'infoLabelMycompetitors': 'By telling us about your competitors, we can customize your experience you receive from our platform. You will receive valuable reporting as well as integration into our various components of our platform. This will assist with understanding the interactions of current competitors vs. new prospects.',

    //configuration -> installation
    'buttonLabelSendInstallationInstructions': 'Send Installation Instructions',
    'buttonTitleCopyToClipboard': 'Copy to clipboard',
    'buttonTitleSaveChanges': 'Save Changes',

    //Installation information
    'infoLabelInstallation': 'Insert this HTML into each of your HTML pages of your website. Paste this below the <Title> tag within the HTML.',

    //heading label
    'headingLabelInstallationScript': 'Installation Script',
    'headingLabelSendInstallationInstructions': 'Send installation instructions',

    //input label
    'inputLabelMessageToRecipient': 'Message to recipient',
    'inputLabelEnterEmail': 'Enter Email',
    'inputLabelInfoInstallation': 'You will receive an email when we receive traffic from your website to let you know the code is working properly.',

    //configuration -> Filter Contacts
    'buttonLabelAddNewFilter': 'Add New Filter',
    'buttonLabelCreateJobTitleFilter': 'Create Job Title Filter',

    //heading label
    'headingLabelFilterContactsbyJobTitle': 'Filter Contacts by Job Title',
    'popupHeadingLabelDeleteContactJobTitleFilter': 'Delete Contact Job Title Filter?',

    //table Label
    'tableHeaderLabelIncludeTheseJobTitles': 'Include these Job Titles',

    'conditionLabelMatchType': 'Contains',

    //popup title
    'popupTitleCreateNewJobTitleFilter': 'Create New Job Title Filter',

    //input label
    'inputLabelJobTitleContains': 'Job Title Contains',
    'infoinputLabelJobTitleContains': 'Please enter your job title to filter.',

    //info label
    'infoLabelCreateAJobTitleFilter': 'When you create a job title filter, the system will filter the available contacts to entries that are important to your business. All filters are matched on a "contains" criteria. This means if you enter "vp" you will receive contacts for any contact with a title that contains those letters, such as "VP of Marketing, VP of Human Resources, VP of Fleet Management". Please keep this in mind when creating your filters.',

    //configuration -> Filter Countries
    'buttonLabelSelectAllCountries': 'Select all countries',
    'buttonLabelUnselectAllCountries': 'Unselect all countries',
    'buttonLabelUnselectEUCountries': 'Unselect EU countries',
    'buttonLabelUpdateYourSelectedCountries': 'Update your selected countries',

    //configuration -> Filter States
    'headingLabelilterStates': 'Filter States',
    'buttonLabelSelectAllStates2': 'Select all states',
    'buttonLabelUnselectAllStates2': 'Unselect all states',
    'buttonLabelUpdateYourSelectedStates': 'Update your selected states',
    'infoLabelFilterStates': 'This data only applies to Visits to your website which includes email tracking. This is a global setting and will effect all users in this account. This setting only processes data from the following states. Data from unselected states will be deleted automatically.',

    //heading label
    'headingLabelilterCountries': 'Filter Countries',

    //info label 
    'infoLabelFilterCountriers': 'Only process data from the following countries. Data from unselected countries will be deleted automatically.',

    //configuration -> filter companies
    'buttonLabelAddaKnownISPEntity': 'Add a Known ISP Entity',
    'buttonLabelAddaKnownISPInBulk': 'Add a Known ISP in Bulk',
    'buttonLabelAddaKnownBusinessEntity': 'Add a Known Business Entity',
    'buttonLabelAddaKnownBusinessInBulk': 'Add a Known Business in Bulk',
    'buttonLabelCreateKnownISPEntry': 'Create  Known ISP Entry',
    'buttonLabelCreateKnownBusinessEntity': 'Create Known Business Entry',

    //Heading label
    'headerLabelCompanyFilters': 'Company Filters',
    'popupHeadingLabelDeleteKnownISPEntry': 'Delete Known ISP Entry?',
    'popupHeadingLabelDeleteKnownBussinessEntry': 'Delete Known Business Entry?',

    //tab label
    'tabLabelKnownISPEntityList': 'Known ISP Entity List',
    'tabLabelKnownBusinessEntityList': 'Known Business Entity List',

    //tabel header label
    'tabelHeaderLabelKnownISPName': 'Known ISP Name',
    'tabelHeaderLabelMatchType': 'Match Type',
    'tabelHeaderLabelKnownBusinessName': 'Known Business Name',

    //input label
    'inputLabelSelectMatchType': 'Select Match Type',
    'inputinfoLabelSeletMatchType': 'Mark any matching company as an ISP.',
    'inputLabelMatchType': 'Match Type',
    'inputLabelPleaseEnterKnownISPs': 'Please enter known ISPs, one per line',
    'inputinfoLabelSelectMatchTypeMessage': "Please consider the implications of the 'contains' match. You can easily include ISPs by accident.",
    'inputinfoLabelKnownBusiness': 'Mark any matching company as a business (not ISP).',
    'inputLabelPleaseEnterKnownBusinesses': 'Please enter known businesses, one per line',

    //popup title 
    'popupTitleCreateNewKnownISPEntry': 'Create New Known ISP Entry',
    'popupTitleCreateNewKnownISPEntriesInBulk': 'Create New Known ISP Entries in Bulk',
    'popupTitleCreateNewKnowBusinessEntry': 'Create New Known Business Entry',

    //filter companies info
    'infoLabelFilterCompanies': 'By filtering companies, you are assisting the system in delivering the exact data you need. This area will allow you to mark companies as ISPs so they will not appear by default in dashboards, reporting and notifications. You can perform the opposite as well, you can mark known companies to prevent them from ever being classified as ISPs. The system will allow you to perform these matches based on exact or contains matching. For exact matching (the safest option), the company name must match your request exactly. Contains matching should be used carefully, as it can have unintended results. Contains matching will match any company name that contains the phrase you input.',

    'infoLabelFilerCompaniesProcess': 'Once you make an entry, the system will review all existing visits and update all matching records. This process can take up to 24 hours.',
    'infoLabelFilterCompaniesCreateAKnownISPEntity': 'When you create a known ISP entity, the system will mark any visiting company with a matching name as an ISP. This removes them by default from lead lists, reports and notifications.',

    'infoLabelCreateNewKnownISPEntriesInBulk': 'When you create a known ISP entity, the system will mark any visiting company with a matching name as an ISP. This removes them by default from lead lists, reports and notifications. All bulk entries will be added as "Exact" name matches.',

    'infoLabelCreateKnownBusinessEntity': 'When you create a known business entity, the system will mark any visiting company with a matching name as a business (not an ISP). This adds them by default from lead lists, reports and notifications.',

    //configuration -> Exclude Ip 
    'buttonLabelAddNewExcludedIP': 'Add New Excluded IP',
    'buttonLabelCreateExcludedIPAddress': 'Create Excluded IP Address',
    'buttonLabelUpdateExcludedIPAddress': 'Update Excluded IP Address',

    //heading label
    'headingLabelExcludedIPAddresses': 'Excluded IP Addresses',
    'popupHeadingLabelDeleteExcludedIP': 'Delete Excluded IP?',
    'inputLabelInfoExcludeIp': 'Please enter an ip address to exclude. This will prevent processing any requests from this source.',

    //Tabel Header
    'tableHeaderExcludedIPAddresses': 'Excluded IP Addresses',

    //popup title
    'popupTitleCreateIPAddressExclusion': 'Create IP Address Exclusion',

    //configuration -> domains
    'buttonLabelAddNewDomain': 'Add New Domains',
    'buttonLabelCreateDomain': 'Create Domain',
    'buttonLabelUpdateDomain': 'Update Domain',

    //heading 
    'headingLabelDomainsZones': 'Domains / Zones',
    'popupHeadingLabelDeleteDomain': 'Delete Domain?',

    'inputLabelDomainInfo': 'Please enter your domain name. This will assist in result filtering.',
    'inputLableActive': 'Active',

    'statusConditionLabelActive': 'Active',
    'statusConditionLabelInActive': 'Inactive',

    //configuration -> Email Campaign
    //button label
    'buttonLabelAddNewEmailCampaign': 'Add New Email Campaign',
    'buttonLabelUpdateEmailCampaign': 'Update Email Campaign',
    'buttonLabelCreateEmailCampaign': 'Create Email Campaign',
    'buttonLabelCreateEmailCampaignLink': 'Create Email Campaign Link',

    'buttonTitleLinkBuilder': 'Link Builder',

    //heading label
    'headingLabelEmailCampaigns': 'Email Campaigns',
    'popupHeadingLabelDeleteEmailCampaign': 'Delete Email Campaign?',

    //popup title
    'popupTitleCreateEmailCampaign': 'Create Email Campaign',
    'popupTitleEditEmailCampaign': 'Edit Email Campaign',
    'popupTitleEmailCampaignLinkBuilder': 'Email Campaign Link Builder',

    //input labels
    'inputLabelShowEntries': 'Show Entries',
    'inputLabelCampaignName': 'Campaign Name',
    'inputLabelCampaignSource': 'Campaign Source',
    'inputLabelSelectCampaignType': 'Select Campaign Type',
    'inputLabelPhoneNumber': 'Phone Number',
    'inputLabelDestinationURL': 'Destination URL',
    'inputLabelSelectOptionalFieldsToInclude': 'Select Optional fields to include',
    'inputinfoLabelCampaignName': 'This must be a unique name for tracking your campaign.',
    'inputinfoLabelCampaignSource': 'This is an optional field for setting the source of your campaign such as "Newsletter 08-15".',
    'inputLabelCampaignTerm': 'Campaign Term',
    'inputinfoLabelCampaignTerm': 'This is an optional field for tracking a term (keywords) in your campaign, such as "Optional Services".',
    'inputLabelCampaignContent': 'Campaign Content',
    'inputinfoLabelCampaignContent': 'This is an optional field for tracking different links in your email campaign, such as "Read white paper now".',
    'inputinfoLabelDestinationUrl': 'This is the url of the link you will place in your email.',
    'inputLabelCutAndPasteThisLinkIntoYourEmail': 'Cut and paste this link into your email',

    //configuration -> Forms Capture
    'buttonLabelAddNewFormCapture': 'Add New Form Capture',
    'buttonLabelSaveFormCapture': 'Save Form Capture',

    //heading label
    'headingLabelFormCapturesConfigured': 'Form Captures Configured',
    'popupHeadingLabelDeleteFormCaptureConfiguration': 'Delete Form Capture Configuration?',
    'headingLabelMapYourFormFieldsBelow': 'Map your form fields below',

    //popup title
    'popupTitleCreateNewFormCapture': 'Create New Form Capture',
    'popupTitleEditFormCaptureConfiguration': 'Edit Form Capture Configuration',

    //tab label
    'tabLabelFormInformation': 'Form Information',
    'tabLabelSelectForm': 'Select Form',
    'tabLabelFormFieldMapping': 'Form Field Mapping',

    // Forms Capture table headers
    'tableHeaderLabelFormName': 'Form Name',
    'tableHeaderLabelFormId': 'Form Id',
    'tabelHeaderLabelSourceYourForm': 'Source (Your Form)',
    'tabelHeaderLabelDestination': 'Destination',

    //input label 
    'inputLabelFormName': 'Form Name',
    'inputLabelFormId': 'Form Id',
    'inputLabelFormURL': 'Form URL',
    'inputLabelNotes': 'Notes',
    'inputLabelSelect': 'Select',
    'inputLabelPleaseSelectTheFormYouWouldLikeToCapture': 'Please select the form you would like to capture',
    'inputLabelSalutation': 'Salutation',
    'inputLabelTitle': 'Title',
    'inputLabelAddress1': 'Address 1',
    'inputLabelAddress2': 'Address 2',
    'inputLabelCity': 'City',
    'inputLabelState': 'State',
    'inputLabelZip': 'Zip',
    'inputLabelPhoneSecondary': 'Phone-Secondary',
    'inputLabelIndustry': 'Industry',
    'inputLabelRevenue': 'Revenue',
    'inputLabelInsertJavascriptCode': 'Please insert the javascript code below on the page that contains your form.',

    //info label
    'infoLabelSelectForm': 'The following forms have been detected on the URL Provided.',

    //configuration -> user
    'buttonLabelPurchaseAdditionalUsers': 'Purchase Additional Users',
    'buttonLabelLinkAccount': 'Link Account',
    'buttonLabelTestPostbackNow': 'Test Postback Now',
    'buttonLabelGenerateNewZapierKey': 'Generate New Zapier Key',
    'buttonLabelMakePrimaryUser': 'Make Primary User',
    'buttonLabelUpdateUser': 'Update User',
    'buttonLabelCreateUser': 'Create User',
    'buttonLabelAddNewUser': 'Add New User',

    //heading label
    'headingLabelUsers': 'Users',
    'headingLabelHTTPPostback': 'HTTP Postback',
    'headingLabelZapierIntegration': 'Zapier Integration',
    'popupHeadingLabelDeleteUser': 'Delete User?',
    'headingLabelPurchaseAdditionalUsers': 'Purchase Additional Users',

    // user table headers
    'tableHeaderLabelEmail': 'Email',
    'tableHeaderLabelFirstName': 'First Name',
    'tableHeaderLabelLastName': 'Last Name',
    'tableHeaderLabelRole': 'Role',

    //popup title
    'popupTitleEditUser': 'Edit User',
    'popupTitleCreateNewUser': 'Create New User',

    //tab label
    'tabLabelUserInformation': 'User Information',
    'tabLabelCRMIntegration': 'CRM Integration',
    'tabLabelOtherIntegration': 'Other Integration',

    //input label
    'inputLabelRole': 'Role',
    'inputLabelConfirmPassword': 'Confirm Password',
    'inputLabelEnableHTTPPostback': 'Enable HTTP Postback',
    'inputLabelPostbackURL': 'Postback URL',
    'inputLabelEnableZapierIntegration': 'Enable Zapier Integration',
    'labelZapierAPIKey': 'Zapier API Key',

    //link label
    'linkLabelZapier': 'Learn more about Zapier.',
    'linkLabelDownloadDocumentation': 'Click here to download our documentation.',

    //configuration users -> crm tab info 
    'infoLabelUserCRM': 'Enabling CRM integration will provide the ability to send the information about your visitors to your CRM system with a single click. When enabled, you will receive a new icon on the prospect detail page. When the "Send to CRM" icon is clicked, the information will be sent to your CRM system in the form of a lead.',

    'infoLabelHTTPPostBack': 'Would you like to make this user an API user for sending outbound HTTP request notifications instead of email based notifications. You can use this to integrate with other systems.',

    'infoLabelEnableZapier': 'Enable Zapier Integration to integrate with over 500 applications.',

    'infoLabelZapierInfo': 'Enabling Zapier Integration will not impact the current notifications from this user account.',

    'infoLabelPurchaseAdditionalUser': 'Please contact your account representative to purchase additional users accounts.',

    //my account -> my information
    //buttons
    'buttonLabelUpdateMyInformation': 'Update My Information',

    //input labels
    'inputLabelFirstName': 'First Name',
    'inputLabelLastName': 'Last Name',
    'inputLabelEmail': 'Email',
    'inputLabelPhone': 'Phone',
    'inputLabelTimeZone': 'Time Zone',

    //heading labels
    'headingLabelMyInformation': 'My Information',

    //my account -> change password
    'buttonLabelUpdateMyPassword': 'Update My Password',

    //heading label
    'headingLabelChangeMyPassword': 'Change My Password',

    //input labels
    'inputLabelNewPassword': 'New Password',
    'inputLabelConfirmNewPassword': 'Confirm New Password',

    //my account -> crm integration
    'buttonLabelUpdateMyCRMIntegration': 'Update My CRM Integration',

    //input labels
    'inputLabelEnableCRMIntegration': 'Enable CRM Integration',
    'inputLabelSelectCRMSystem': 'Select CRM System',
    'inputLabelAPIURL': 'API URL',
    'inputLabelSecurityToken': 'Security Token',
    'inputLabelUserName': 'User Name',
    'inputLabelPassword': 'Password',
    'inputLabelPushWebsiteVisitsToCRMForUntaggedVisitors': 'Push website visits to CRM for "Untagged Visitors"',
    'inputLabelPushWebsiteVisitsToCRMForTaggedVisitors': 'Push website visits to CRM for "Tagged Visitors"',

    //heading labels
    'headingLabelConfigureMyCRMIntegration': 'Configure My CRM Integration',

    //crm information
    'infoLabelCRM': 'Enabling CRM integration will provide the ability to send the information about your visitors to your CRM system with a single click. When enabled, you will receive a new icon on the prospect detail page. When the "Send to CRM" icon is clicked, the information will be sent to your CRM system in the form of a lead.',

    //crm table headers
    'tableHeaderLabelSource': 'Source',
    'tableHeaderLabelAction': 'Action',
    'statusConditionLabelNotConnected': 'Not Connected',
    'statusConditionLabelConnected': 'Connected',

    //lead handling -> notification rule
    'buttonLabelAddURLToWatch': 'Add URL to Watch',
    'buttonLabelUpdateMyNotificationRules': 'Update My Notification Rules',
    'buttonLabelCreateNotificationUrl': 'Create Notification URL',

    // lead handling -> territory rules
    'buttonLabelSelectAllStates': 'Select all states',
    'buttonLabelUnselectAllStates': 'Unselect all states',
    'buttonLabelUpdateYourUsTerritory': 'Update your US territory',
    'buttonLabelUpdateYourInternationalTerritory': 'Update your international territory',

    //lead handling -> watch exclusion list
    'buttonLabelCreateExclusionListEntry': 'Create Exclusion List Entry',
    'buttonLabelAddCompanyToWatchList': 'Add Company to Watch List',
    'buttonLabelAddCompanyToExludeList': 'Add Company to Exclude List',
    'buttonLabelCreateWatchListEntry': 'Create Watch List Entry',

    //Reseller -> Reseller Settings
    'buttonTitleResetToDefault': 'Reset to Default',

    //Reseller -> Reseller Report
    'buttonLabelRunInactiveCustomerSummaryReport': 'Run Inactive Customer Summary Report',
    'buttonLabelRunInactiveCustomerActivityReport': 'Run Customer Activity Report',
    'buttonLabelRunConsumerCustomerActivityReport': 'Run Consumer (+Person) Customer Activity Report',
    'buttonLabelRunConsumerResellerDetailReport': 'Run Consumer (+Person) Reseller Detail Report',
    'buttonLabelRunReport': 'Run Consumer (+Person) Reseller Summary Report',

    //Reseller -> Reseller Manage
    'buttonLabelUpdateReseller': 'Update Reseller',
    'buttonLabelLoginAsReseller': 'Login As Reseller',

    //Reseller Billing Map
    'buttonLabelUpdateResellerComponents': 'Update Reseller Components',

    //Reseller -> Customer
    'buttonLabelAddNewCustomer': 'Add New Customer',
    'buttonLabelCreateCustomer': 'Create Customer',
    'buttonLabelExtendTrial': 'Extend Trial',
    'buttonLabelDisableEmailMarketing': 'Disable Email Marketing',
    'buttonLabelEndPaidAccount': 'End Paid Account',
    'buttonLabelTerminate': 'Terminate',
    'buttonLabelCreateReseller': 'Create Reseller',
    'buttonLabelEmailBeta': 'Email Beta',
    'buttonLabelZapierBeta': 'Zapier Beta',
    'buttonLabelContactExpBeta': 'Contact Exp Beta',
    'buttonLabelCallTrackingBeta': 'Call Tracking Beta',
    'buttonLabelEndFreeTrialMakePaidAccount': 'End Free Trial. Make Paid Account.',
    'buttonLabelMakeReseller': 'Make Reseller',
    'buttonLabelDisableMarketing': 'Disable Marketing',
    'buttonLabelUpdateCustomer': 'Update Customer',
    'buttonTitleLoginasCustomer': 'Login as Customer',

    // billing -> billing info
    'buttonLabelUpdateCreditCard': 'Update Credit Card',
    'buttonLabelPurchaseUserAccounts': 'Purchase User Accounts',
    'buttonLabelCancelUserAccounts': 'Cancel User Accounts',
    'buttonLabelRemoveAdditionalUsers': 'Remove Additional Users',

    //billing -> invoice 
    'buttonTitleViewDetails': 'View Details',

    //call tracking -> Dashboard
    'buttonLabelUpdateInfo': 'Update Info',
    'buttonTitleViewCallDetails': 'View Call Details',
    'buttonLabelHideTranscription': 'Hide Transcription',
    'buttonLabelShowTranscription': 'Show Transcription',

    //call Tracking -> Reports
    'buttonLabelRunCallDetailReport': 'Run Call Detail Report',
    'buttonLabelRunCampaignSummaryReport': 'Run Campaign Summary Report',
    'buttonLabelRunCallVolumeReport': 'Run Call Volume Report',
    'buttonLabelRunCallTrackingRawDataReport': 'Run Call Tracking Raw Data Report',
    'buttonLabelRunCallTrackingBillingSummaryReport': 'Run Call Tracking Billing Summary Report',

    //call tracking -> numbers
    'buttonLabelAddNewPhoneNumber': 'Add New Phone Number',
    'buttonLabelYesIllUseItOnline': "Yes I'll use it online",
    'buttonLabelYesIllUseItOffline': "Yes I'll use it Offline",
    'buttonLabelNoJustTrack': 'No, just track',
    'buttonLabelCreatePool': 'Create Pool',
    'buttonLabelCreateSingle': 'Create Single',
    'buttonLabelShowFormattingOptions': 'Show Formatting Options',
    'buttonLabelHideFormattingOptions': 'Hide Formatting Options',
    'buttonLabelFindNumbers': 'Find Numbers',
    'buttonLabelYesUseDNI': 'Yes, use DNI',
    'buttonLabelFinishProvision': 'Finish - Provision',
    'buttonLabelNextReview': 'Next - Review',
    'buttonLabelNextPostCallOptions': 'Next - Post Call Options',
    'buttonLabelNextOptions': 'Next - Options',
    'buttonLabelNextFindNumbers': 'Next - Find Numbers',
    'buttonLabelNextConfigureTracking': 'Next - Configure Tracking',

    //call tracking -> Integration
    'buttonLabelSaveGoogleAnalyticsIntegration': 'Save Google Analytics Integration',

    //find-anyone commonly used
    'buttonLabelClearSearchCriteria': 'Clear Search Criteria',
    'buttonLabelDownloadChromeExtension': 'Download Chrome Extension',

    //find-anyone -> Saved Contacts
    'labelAddTagtoSelected': 'Add Tag to Selected',
    'labelRemoveTagfromSelected': 'Remove Tag from Selected',
    'labelManageTags': 'Manage Tags',

    //find-anyone -> Search Contacts
    'buttonLabelDownloadLimit': 'Download Limit',
    'buttonLabelTotalContacts': 'Total Contacts',
    'buttonLabelSaveContacts': 'Save Contacts',

    //find-anyone -> search companies 
    //headinglabel
    'headingLabelSelectedCriteria': 'Selected Criteria',
    'headingLabelSearchCriteria': 'Search Criteria',
    'headingCollapsableLabelFirmographic': 'Firmographic',
    'headingLabelFindCompanies': 'Find Companies',
    'headingLabelSuggestions': 'Suggestions',
    'headingLabelSearchTopic1': 'Search Topic 1',
    'headingLabelTopAlignedMedia': 'Top Aligned Media',

    //input label
    'inputLabelName': 'Name',
    'inputLabelWebsite': 'Website',
    'inputLabelSICCode': 'SIC Code',
    'inputLabelNAICSCode': 'NAICS Code',
    'inputLabelEmployeeSize': 'Employee Size',
    'inputLabelRevenueBand': 'Revenue Band',
    'inputLabelLocationType': 'Location Type',
    'inputLabelLocation': 'Location',
    'inputLabelCountry': 'Country',

    // Quick Search
    'inputLabelCompanyName': 'Company Name',
    'inputLabelContactName': 'Contact Name',
    'inputLabelCompanyWebAddress': 'Company Web Address',
    'inputLabelJobTitles': 'Job Titles',

    // Advanced  Search
    'inputLabelJobCriteria': 'Job Criteria',
    'inputLabelCompanyCriteria': 'Company Criteria',
    'inputLabelLocationCriteria': 'Location Criteria',
    'inputLabelPersonalCriteria': 'Personal Criteria',
    'inputLabelSuppressionCriteria': 'Suppression Criteria',
    'inputLabelListCriteria': 'List Criteria',
    'inputLabelSavedCriteria': 'Saved Criteria',

    'inputLabelJobTitle': 'Job Title',
    'inputLabelJobLevel': 'Job Level',
    'inputLabelJobFunction': 'Job Function',
    'inputLabelJobStartDate': 'Job Start Date',
    'inputLabelRecentChangedJob': 'Recent Changed Job',

    'inputLabelCompanyIndustry': 'Company Industry',
    'inputLabelCompanyEmployeeSize': 'Company Employee Size',
    'inputLabelCompanyRevenueBand': 'Company Revenue Band',
    'inputLabelCompanyNAICS': 'Company NAICS',
    'inputLabelCompanySIC': 'Company SIC',

    'inputLabelContactRegion': 'Contact Region',
    'inputLabelAddressType': 'Address Type',
    'inputLabelContactCountry': 'Contact Country',
    'inputLabelContactState': 'Contact State',
    'inputLabelContactCity': 'Contact City',
    'inputLabelContactZip': 'Contact Zip',

    'inputLabelContactGender': 'Contact Gender',
    'inputLabelContactAlmaMater': 'Contact Alma Mater',
    'inputLabelContactAge': 'Contact Age',
    'inputLabelContactSkill': 'Contact Skill',
    'inputLabelContactFormerEmployer': 'Contact Former Employer',
    'inputLabelPersonalEmailAddressRequired': 'Personal Email Address Required',
    'inputLabelCellPhoneRequired': 'Cell Phone Required',

    'inputLabelContactTitles': 'Contact Titles',
    'inputLabelEmailAddress': 'Email Address',
    'inputLabelExcludeMyLeads': 'Exclude My Leads',

    'inputLabelPrimaryContact': 'Primary Contact',
    'inputLabelProfessionalEmail': 'Professional Email',
    'inputLabelEmailConfidenceScore': 'Email Confidence Score',
    'inputLabelDeterministicEmail': 'Deterministic Email',

    'inputLabelTags': 'Tags',
    'inputLabelDateSaved': 'Date Saved',
    'inputLabelSavedFrom': 'Saved From',
    'inputLabelSavedBy': 'Saved By',
    'inputLabelLastExported': 'Last Exported',
    'inputLabelExcludeExported': 'Exclude Exported',

    //info labels
    'infoLableRefineYourSearch': 'Refine your search Using the search criteria.',
    'infoLabelNoCompaniesFound': 'Oops! No companies found with your search.',
    'infoLabelTryGeneralizedSearchCriteria': 'Try a more generalized search criteria.',
    'infoLabelTermsSpelledCorrectly': 'Make sure your names and terms are spelled correctly.',
    'infoLabelTryDifferentCriteria': 'Try different criteria',
    'infoLabelPleaseSelectCompaniesToExport': 'Please select companies to export.',
    'infoLabelOneSearchTip': 'Chocolate bar apple pie lollipop pastry candy canes. Candy soufflé brownie toffee tootsie roll. Brownie lemon drops chocolate cake donut croissant cotton candy ice cream. Chocolate pudding jelly beans powder carrot cake pastry. Sweet candy dragée cake brownie cheesecake jelly beans tart lemon drops. Liquorice cake fruitcake dragée sesame snaps candy canes biscuit pastry. Cookie caramels brownie carrot cake macaroon brownie pastry. Donut powder croissant tootsie roll cake cupcake. Cake tart icing jelly-o ice cream tootsie roll bear claw toffee brownie. Sugar plum danish muffin gummi bears. Bonbon chocolate bar candy canes sugar plum pie gingerbread wafer chupa chups gummi bears. Carrot cake oat cake jujubes cookie. Marzipan topping jelly brownie bear claw. Bonbon gummies fruitcake liquorice tart sesame snaps.',
    'infoLabelDoYouWishToContinue': 'Do you wish to continue?',
    'infoLabelPleaseSelectContactsToExport': 'Please select contacts to export.',
    'infoLabelYourDailyContactDownloadLimitIsOver': 'Your daily contact download limit is over.',
    'infoLabelContactsAddedSuccessfully': 'Contacts Added successfully.',

    //popup label
    'popupTitleLabelSearchTips': 'Search Tips',
    'popupTitleLabelDownloadContacts': 'Download Contacts',
    'popupTitleLabelAddContacts': 'Add Contacts',
    'popupTitleLabelDownloadCompanies': 'Download Companies',
    'popupTitleLabelContactDetails': 'Contact Details',
    'popupTitleLabelSavedContacts': 'Saved Contacts',

    // find-anyone tabel header 
    'tableHeaderLabelIndustry': 'Industry',
    'tableHeaderLabelEmployee': 'Employee',
    'tableHeaderLabelLocation': 'Location',
    'tableHeaderLabelShared': 'Shared',

    //label
    'LabelTotalCompanies': 'Total Companies',
    'LabelExportExcel': 'Export Excel',
    'LabelExportCSV': 'Export CSV',
    'LabelDownloadExcel': 'Download Excel',
    'LabelDownloadCSV': 'Download CSV',

    //find-anyone -> company details Model (component)
    'buttonLabelUpdateProspect': 'Update Prospect',
    'buttonLabelForwardProspect': 'Forward Prospect',
    'buttonLabelTag': 'Tag',
    'buttonLabelSendToCRM': 'Send to CRM',
    'buttonLabelSetAsCompany': 'Set as Company',
    'buttonLabelSetAsISP': 'Set as ISP',
    'buttonLabelForward': 'Forward',
    'buttonLabelWatch': 'Watch',
    'buttonLabelExclude': 'Exclude',
    'buttonTitleShowExtendedComapnyDetails': 'Show Extended Company Details',
    'buttonLabelFilterVisitors': 'Filter Visitors',

    'popupTitleCompanyDetails': 'Company Details',

    //Reviews -> Gather Reviews
    'buttonLabelManageBlockList': 'Manage Block List',
    'buttonLabelEmailSetting': 'Email Setting',
    'buttonLabelBlockEmail': 'Block Email',
    'buttonLabelAddToList': 'Add To List',
    'buttonLabelCreateContact': 'Create Contact',
    'buttonLabelSelectMedia': 'Select Media',
    'buttonLabelInsertField': 'Insert Field',
    'buttonLabelSendTestEmail': 'Send Test Email',
    'buttonLabelSaveNewEmail': 'Save New Email',
    'buttonLabel1stPartyInternal': '1st Party (internal)',
    'buttonLabel3rdPartyExternal': '3rd Party (external)',
    'buttonLabelEmailOnly': 'Email Only',
    'buttonLabelEmailWithSentiment': 'Email with Sentiment',
    'buttonLabelAddDripEmail': 'Add Drip Email',
    'buttonLabelNextCampaignName': 'Next - Campaign Name',
    'buttonLabelNextConfigureDripEmails': 'Next - Configure Drip Emails',
    'buttonLabelFinishUpdateCampaign': 'Finish - Update Campaign',
    'buttonLabelUpdateEmailSettings': 'Update Email Settings',

    // Reviews -> Landing Pages
    'buttonLabelAddLandingPage': 'Add Landing Page',
    'buttonLabelSubmit': 'Submit',

    //Reviews -> Getting started
    'buttonLabelAddLocation': 'Add Location',
    'buttonLabelAddGMBProfile': 'Add GMB Profile',
    'buttonLabelAddFacebookProfile': 'Add Facebook Profile',
    'buttonLabelFix': 'Fix',
    'buttonLabelRemoveSite': 'Remove Site',
    'buttonLabelViewListing': 'View Listing',
    'buttonLabelMakeThisMylisting': 'Make this my listing',
    'buttonLabelOverrideURL': 'Override URL',
    'buttonLabelCreateListing': 'Create Listing',
    'buttonLabelSelectCompany': 'Select Company',

    //Reviews -> Review Details
    'buttonLabelShareOnFacebook': 'Share on Facebook',
    'buttonLabelGoToReview': 'Go To Review',
    'buttonLabelRespond': 'Respond',
    'buttonLabelPostyourResponse': 'Post your Response',
    'buttonLabelUpdateReview': 'Update Review',

    //Reviews -> widget 
    //buttons
    'buttonLabelSaveAutoPublish': 'Save Auto Publish',

    // Consumer --> Leads --> Search Lead
    'labelVisitorsIdentified': 'Visitors Identified',
};