/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


const mutations = {


  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width
  },


  // VxAutoSuggest

  UPDATE_STARRED_PAGE(state, payload) {

    // find item index in search list state
    const index = state.navbarSearchAndPinList["pages"].data.findIndex((item) => item.url == payload.url)

    // update the main list
    state.navbarSearchAndPinList["pages"].data[index].is_bookmarked = payload.val

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList["pages"].data[index])
    }
    else {
      // find item index from starred pages
      const index = state.starredPages.findIndex((item) => item.url == payload.url)

      // remove item using index
      state.starredPages.splice(index, 1)
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10)
    state.starredPages = list.concat(starredPagesMore)
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false
    let lastItemInStarredLimited = state.starredPages[10]
    const starredPagesLimited = state.starredPages.slice(0, 10)
    state.starredPages = starredPagesLimited.concat(list)

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true
    })

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited)
    }
  },


  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) { state.bodyOverlay = val },
  UPDATE_PRIMARY_COLOR(state, val) { state.themePrimaryColor = val },
  UPDATE_FRIST_COLOR(state, val) { state.themeFirstColor = val },
  UPDATE_SECOND_COLOR(state, val) { state.themeSecondColor = val },
  UPDATE_THIRD_COLOR(state, val) { state.themeThirdColor = val },
  UPDATE_FOURTH_COLOR(state, val) { state.themeFourthColor = val },
  
  UPDATE_ACTION_BUTTON_COLOR(state, val) { state.themeactionbutton = val },

  UPDATE_CHART1_COLOR(state, val) { state.themechart1 = val },
  UPDATE_CHART2_COLOR(state, val) { state.themechart2 = val },
  UPDATE_CHART3_COLOR(state, val) { state.themechart3 = val },
  UPDATE_CHART4_COLOR(state, val) { state.themechart4 = val },
  UPDATE_CHART5_COLOR(state, val) { state.themechart5 = val },
  UPDATE_CHART6_COLOR(state, val) { state.themechart6 = val },
  UPDATE_CHART7_COLOR(state, val) { state.themechart7 = val },
  UPDATE_CHART8_COLOR(state, val) { state.themechart8 = val },


  UPDATE_THEME(state, val) { state.theme = val },
  UPDATE_WINDOW_WIDTH(state, width) { state.windowWidth = width },
  UPDATE_WINDOW_SCROLL_Y(state, val) { state.scrollY = val },


  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_USER_INFO(state, payload) {

    // Get Data localStorage
    let userInfo = JSON.parse(localStorage.getItem("userInfo")) || state.AppActiveUser

    for (const property of Object.keys(payload)) {

      if (payload[property] != null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }


    }
    // Store data in localStorage
    localStorage.setItem("userInfo", JSON.stringify(userInfo))
  },
  UPDATE_NAVBAR_COLOR(state,val){
    state.navbarDynamicColor = val;
  },
  UPDATE_AUTH_TOKEN(state, val) {
    state.authTokenVV = val;
  },
  UPDATE_START_DATE(state, val) {
    state.filterFromDate = val;
  },
  UPDATE_END_DATE(state, val) {
    state.filterToDate = val;
  },

  UPDATE_IS_RESELLER(state, val){
    state.isReseller = val;
  },
  UPDATE_MAX_TALL_FREE_NUMBERS(state, val){
    state.maxTollFreeNumber = val;
  },
  UPDATE_MAX_REVIEW_LOCATIONS(state, val){
    state.maxReviewLocations = val;
  },
  UPDATE_MAX_USERS(state, val){
    state.maxUsers = val;
  },
  UPDATE_ENABLE_ZAPIER_INTEGRATION(state, val){
    state.enableZapierIntegration = val;
  },
  UPDATE_LOGGED_IN_ACCOUNT_LOGO(state, val){
    state.loggedInAccountLogo = val;
  },
  UPDATE_DOMAIN_URL(state,val){
    state.actualDomainName = val;
  },
  UPDATE_USER_DETAILS(state, val){
    state.userDetails = val;
  },

  UPDATE_SHOW_CALL_TRACKING_BILLING_SUMMARY_FOR_RESELLERS(state, val){
    state.showCallTrackingBillingSummaryForResellers = val;
  },

  UPDATE_COMPANY_ACCOUNT_ID(state, val){
    state.companyAccountId = val;
  },

  APP_VERSION(state, val){
		state.appVersion = val;
	},
}

export default mutations

