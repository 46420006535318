/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [

        {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () =>
                import ('./layouts/main/Main.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                //B2C
                //consumer
                {
                    path: '/consumer/dashboard/uid/:id/:accountid?',
                    name: 'ConsumerDashboard',
                    component: () =>
                        import ('./views/consumer/dashboard/Dashboard.vue')
                },
                {
                    path: '/consumer/lead/search-leads/uid/:id/:accountid?',
                    name: 'ConsumerSearchLeads',
                    component: () =>
                        import ('./views/consumer/leads/SearchLeads.vue')
                },
                {
                    path: '/consumer/configuration/budget/uid/:id/:accountid?',
                    name: 'ConsumerConfigurationBudget',
                    component: () =>
                        import ('./views/consumer/configuration/Budget.vue')
                },
                {
                    path: '/consumer/billing/billing-info/uid/:id/:accountid?',
                    name: 'ConsumerBillingInfo',
                    component: () =>
                        import ('./views/consumer/billing/BillingInfo.vue')
                },
                //B2B
                // Dashboard
                {
                    path: '/dashboard/uid/:id/:accountid?',
                    name: 'Dashboard',
                    component: () =>
                        import ('./views/dashboard/Dashboard.vue')
                },

                // Leads
                {
                    path: '/leads/search-lead/uid/:id/:accountid?',
                    name: 'Search Lead',
                    component: () =>
                        import ('./views/leads/Searchlead.vue')
                },
                {
                    path: '/leads/form-captures/uid/:id/:accountid?',
                    name: 'Form Captures',
                    component: () =>
                        import ('./views/leads/Formscaptured.vue')
                },
                {
                    path: '/leads/email-campaigns/uid/:id/:accountid?',
                    name: 'Email Campaigns',
                    component: () =>
                        import ('./views/leads/Emailcampaign.vue')
                },
                {
                    path: '/leads/reporting/uid/:id/:accountid?',
                    name: 'Reporting',
                    component: () =>
                        import ('./views/leads/Reporting.vue')
                },
                //Intent
                {
                    path: '/intent/dashboard/uid/:id/:accountid?',
                    name: 'IntentDashboard',
                    component: () =>
                        import ('./views/intent/Dashboard.vue')
                },
                {
                    path: '/intent/leads/uid/:id/:accountid?',
                    name: 'IntentLeads',
                    component: () =>
                        import ('./views/intent/Leads.vue')
                },
                {
                    path: '/intent/campaigns/uid/:id/:accountid?',
                    name: 'IntentCampaigns',
                    component: () =>
                        import ('./views/intent/Campaigns.vue')
                },
                {
                    path: '/intent/lead-routing/uid/:id/:accountid?',
                    name: 'LeadRouting',
                    component: () =>
                        import ('./views/intent/LeadRouting.vue')
                },
                {
                    path: '/intent/competitors/uid/:id/:accountid?',
                    name: 'IntentCompetitors',
                    component: () =>
                        import ('./views/intent/Competitors.vue')
                },
                {
                    path: '/intent/mycustomerprofile/uid/:id/:accountid?',
                    name: 'MyCustomerProfile',
                    component: () =>
                        import ('./views/intent/MyCustomerProfile.vue')
                },
                {
                    path: '/intent/prebuiltcampaigns/uid/:id/:accountid?',
                    name: 'PrebuiltCampaigns',
                    component: () =>
                        import ('./views/intent/PrebuiltCampaigns.vue')
                },
                {
                    path: '/intent/keywordsummary/uid/:id/:accountid?',
                    name: 'KeywordSummary',
                    component: () =>
                        import ('./views/intent/KeywordSummary.vue')
                },
                // Find Anyone
                {
                    path: '/find-anyone/search-contacts/uid/:id/:accountid?',
                    name: 'SearchContacts',
                    component: () =>
                        import ('./views/find-anyone/SearchContacts.vue')
                },
                {
                    path: '/find-anyone/Save-contacts/uid/:id/:accountid?',
                    name: 'SaveContacts',
                    component: () =>
                        import ('./views/find-anyone/SavedContacts.vue')
                },
                {
                    path: '/find-anyone/search-companies/uid/:id/:accountid?',
                    name: 'SearchCompanies',
                    component: () =>
                        import ('./views/find-anyone/SearchCompanies.vue')
                },
                {
                    path: '/find-anyone/data-enrichment/uid/:id/:accountid?',
                    name: 'DataEnrichment',
                    component: () =>
                        import ('./views/find-anyone/DataEnrichment.vue')
                },

                // Email Marketing
                {
                    path: '/email-marketing/dashboard/uid/:id/:accountid?',
                    name: 'EmailDashboard',
                    component: () =>
                        import ('./views/email-marketing/Dashboard.vue')
                },
                {
                    path: '/email-marketing/contacts/uid/:id/:accountid?',
                    name: 'Contacts',
                    component: () =>
                        import ('./views/email-marketing/Contacts.vue')
                },
                {
                    path: '/email-marketing/settings/uid/:id/:accountid?',
                    name: 'Settings',
                    component: () =>
                        import ('./views/email-marketing/Settings.vue')
                },
                {
                    path: '/email-marketing/Campaigns/uid/:id/:accountid?',
                    name: 'Campaigns',
                    component: () =>
                        import ('./views/email-marketing/Campaigns.vue')
                },
                {
                    path: '/email-marketing/test-email-editor/uid/:id/:accountid?',
                    name: 'TestEmailEditor',
                    component: () =>
                        import ('./views/email-marketing/TestEmailEditor.vue')
                },
                {
                    path: '/email-marketing/getting-started/uid/:id/:accountid?',
                    name: 'GettingStarted',
                    component: () =>
                        import ('./views/email-marketing/gettingStarted.vue')
                },

                // Call Tracking (New)
                {
                    path: '/call-tracking/getting-started/uid/:id/:accountid?',
                    name: 'Call Tracking Getting Started',
                    component: () =>
                        import ('./views/call-tracking/Gettingstarted.vue')
                },
                {
                    path: '/call-tracking/call-dashboard/uid/:id/:accountid?',
                    name: 'Call Dashboard',
                    component: () =>
                        import ('./views/call-tracking/Calldashboard.vue')
                },
                {
                    path: '/call-tracking/text-msg-inbox/uid/:id/:accountid?',
                    name: 'Text Msg Inbox',
                    component: () =>
                        import ('./views/call-tracking/textmessageinbox.vue')
                },
                {
                    path: '/call-tracking/numbers/uid/:id/:accountid?',
                    name: 'Numbers',
                    component: () =>
                        import ('./views/call-tracking/Calltrackingnumbers.vue')
                },
                {
                    path: '/call-tracking/integration/uid/:id/:accountid?',
                    name: 'Integration',
                    component: () =>
                        import ('./views/call-tracking/Integration.vue')
                },
                {
                    path: '/call-tracking/reports/uid/:id/:accountid?',
                    name: 'Reports',
                    component: () =>
                        import ('./views/call-tracking/Callreportlist.vue')
                },

                // Reputation Management (Reviews)
                {
                    path: '/reputation-management/getting-started/uid/:id/:accountid?',
                    name: 'Getting Started',
                    component: () =>
                        import ('./views/reputation-management/ReputationManagementGettingStarted.vue')
                },
                {
                    path: '/reputation-management/review-dashboard/uid/:id/:accountid?',
                    name: 'Review Dashboard',
                    component: () =>
                        import ('./views/reputation-management/ReviewDashboard.vue')
                },
                {
                    path: '/reputation-management/review-details/uid/:id/:accountid?',
                    name: 'Review Details',
                    component: () =>
                        import ('./views/reputation-management/ReviewDetails.vue')
                },
                {
                    path: '/reputation-management/gather-reviews/uid/:id/:accountid?',
                    name: 'Gather Reviews',
                    component: () =>
                        import ('./views/reputation-management/GatherReviews.vue')
                },
                {
                    path: '/reputation-management/landing-pages/uid/:id/:accountid?',
                    name: 'Landing Pages',
                    component: () =>
                        import ('./views/reputation-management/LandingPages.vue')
                },
                {
                    path: '/reputation-management/widgets/uid/:id/:accountid?',
                    name: 'Widgets',
                    component: () =>
                        import ('./views/reputation-management/Widgets.vue')
                },

                // Client Email
                {
                    path: '/client-email/getting-started/uid/:id/:accountid?',
                    name: 'Email Tracking Getting Started',
                    component: () =>
                        import ('./views/client-email/Emailtrackgetstarted.vue')
                },
                {
                    path: '/client-email/email-dashboard/uid/:id/:accountid?',
                    name: 'Client Email Dashboard',
                    component: () =>
                        import ('./views/client-email/Clientemaildashboard.vue')
                },
                {
                    path: '/client-email/activity/uid/:id/:accountid?',
                    name: 'Email Activity',
                    component: () =>
                        import ('./views/client-email/Emailactivity.vue')
                },
                {
                    path: '/client-email/templates/uid/:id/:accountid?',
                    name: 'Client Email Templates',
                    component: () =>
                        import ('./views/client-email/Clientemailtemplates.vue')
                },

                // Analytics
                {
                    path: '/analytics/overview/uid/:id/:accountid?',
                    name: 'Overview',
                    component: () =>
                        import ('./views/analytics/Analyticsoverview.vue')
                },
                {
                    path: '/analytics/location/uid/:id/:accountid?',
                    name: 'Location',
                    component: () =>
                        import ('./views/analytics/Analyticslocation.vue')
                },
                {
                    path: '/analytics/device/uid/:id/:accountid?',
                    name: 'Device',
                    component: () =>
                        import ('./views/analytics/Device.vue')
                },
                {
                    path: '/analytics/engagement/uid/:id/:accountid?',
                    name: 'Engagement',
                    component: () =>
                        import ('./views/analytics/Analyticsengagement.vue')
                },
                {
                    path: '/analytics/referrer/uid/:id/:accountid?',
                    name: 'Referrer',
                    component: () =>
                        import ('./views/analytics/Analyticsreferrer.vue')
                },
                {
                    path: '/analytics/entry/uid/:id/:accountid?',
                    name: 'Entry',
                    component: () =>
                        import ('./views/analytics/Analyticsentry.vue')
                },
                {
                    path: '/analytics/exit/uid/:id/:accountid?',
                    name: 'Exit',
                    component: () =>
                        import ('./views/analytics/Analyticsexit.vue')
                },

                // Lead Handling
                {
                    path: '/lead-handling/notification-rules/uid/:id/:accountid?',
                    name: 'Notification Rule',
                    component: () =>
                        import ('./views/lead-handling/Notificationrule.vue')
                },
                {
                    path: '/lead-handling/territory-rules/uid/:id/:accountid?',
                    name: 'Territory Rules',
                    component: () =>
                        import ('./views/lead-handling/Territoryrules.vue')
                },
                {
                    path: '/lead-handling/watch-exclude-rules/uid/:id/:accountid?',
                    name: 'Watch / Exclude Rules',
                    component: () =>
                        import ('./views/lead-handling/Watchexclusionlists.vue')
                },

                // My Account
                {
                    path: '/my-account/my-information/uid/:id/:accountid?',
                    name: 'My Information',
                    component: () =>
                        import ('./views/my-account/MyInformation.vue')
                },
                {
                    path: '/my-account/change-password/uid/:id/:accountid?',
                    name: 'Change Password',
                    component: () =>
                        import ('./views/my-account/Changepassword.vue')
                },
                {
                    path: '/my-account/crm-integration/uid/:id/:accountid?',
                    name: 'CRM Integration',
                    component: () =>
                        import ('./views/my-account/Crmintegration.vue')
                },

                //Integration Status
                {
                    path: '/integration-status/jobactivity/uid/:id/:accountid?',
                    name: 'IntegrationJobActivity',
                    component: () =>
                        import ('./views/integration-status/JobActivity.vue')
                },
                {
                    path: '/integration-status/targetcompanylist/uid/:id/:accountid?',
                    name: 'TargetCompanyList',
                    component: () =>
                        import ('./views/integration-status/TargetCompanyList.vue')
                },
                {
                    path: '/integration-status/targetcontactlist/uid/:id/:accountid?',
                    name: 'TargetContactList',
                    component: () =>
                        import ('./views/integration-status/TargetContactList.vue')
                },

                // Billing
                {
                    path: '/billing/billing-info/uid/:id/:accountid?',
                    name: 'Billing Info',
                    component: () =>
                        import ('./views/billing/Billinginfo.vue')
                },
                {
                    path: '/billing/invoice/uid/:id/:accountid?',
                    name: 'Invoice',
                    component: () =>
                        import ('./views/billing/Invoice.vue')
                },
                {
                    path: '/billing/audit-log/uid/:id/:accountid?',
                    name: 'Audit Log',
                    component: () =>
                        import ('./views/billing/Auditlog.vue')
                },
                {
                    path: '/billing/credit/uid/:id/:accountid?',
                    name: 'Billing Credit',
                    component: () =>
                        import ('./views/billing/Credit.vue')
                },

                // Configuration
                {
                    path: '/configuration/user/uid/:id/:accountid?',
                    name: 'User',
                    component: () =>
                        import ('./views/configuration/User.vue')
                },
                {
                    path: '/configuration/Domains/uid/:id/:accountid?',
                    name: 'Domains',
                    component: () =>
                        import ('./views/configuration/Domains.vue')
                },
                {
                    path: '/configuration/installation-old/uid/:id/:accountid?',
                    name: 'InstallationOld',
                    component: () =>
                        import ('./views/configuration/InstallationOld.vue')
                },
                {
                    path: '/configuration/installation/uid/:id/:accountid?',
                    name: 'Installation',
                    component: () =>
                        import ('./views/configuration/Installation.vue')
                },
                {
                    path: '/configuration/connectors/uid/:id/:accountid?',
                    name: 'Connectors',
                    component: () =>
                        import ('./views/configuration/Connectors.vue')
                },
                {
                    path: '/configuration/excludedip/uid/:id/:accountid?',
                    name: 'Excluded Ip',
                    component: () =>
                        import ('./views/configuration/ExcludedIp.vue')
                },
                {
                    path: '/configuration/emailcampaigns/uid/:id/:accountid?',
                    name: 'Configuration Email Campaigns',
                    component: () =>
                        import ('./views/configuration/ConfigurationEmailCampaigns.vue')
                },
                {
                    path: '/configuration/formscapture/uid/:id/:accountid?',
                    name: 'Forms Capture',
                    component: () =>
                        import ('./views/configuration/ConfigurationFormsCapture.vue')
                },
                {
                    path: '/configuration/filtercompanies/uid/:id/:accountid?',
                    name: 'Filter Companies',
                    component: () =>
                        import ('./views/configuration/FilterCompanies.vue')
                },
                {
                    path: '/configuration/filtercountries/uid/:id/:accountid?',
                    name: 'Filter Countries',
                    component: () =>
                        import ('./views/configuration/FilterCountries.vue')
                },
                {
                    path: '/configuration/filterstates/uid/:id/:accountid?',
                    name: 'FilterStates',
                    component: () =>
                        import ('./views/configuration/FilterStates.vue')
                },
                {
                    path: '/consumer/configuration/filter-zipcodes/uid/:id/:accountid?',
                    name: 'FilterZipcodes',
                    component: () =>
                        import ('./views/consumer/configuration/FilterZipcodes.vue')
                },
                {
                    path: '/configuration/filterjobtitle/uid/:id/:accountid?',
                    name: 'Filter Contacts',
                    component: () =>
                        import ('./views/configuration/FilterContacts.vue')
                },
                {
                    path: '/configuration/mycustomers/uid/:id/:accountid?',
                    name: 'My Customers',
                    component: () =>
                        import ('./views/configuration/MyCustomers.vue')
                },
                {
                    path: '/configuration/mycompetitors/uid/:id/:accountid?',
                    name: 'My Competitors',
                    component: () =>
                        import ('./views/configuration/MyCompetitors.vue')
                },
                {
                    path: '/configuration/integrationpartners/uid/:id/:accountid?',
                    name: 'IntegrationPartners',
                    component: () =>
                        import ('./views/configuration/IntegrationPartners.vue')
                },
                {
                    path: '/configuration/setting/uid/:id/:accountid?',
                    name: 'IntegrationSetting',
                    component: () =>
                        import ('./views/configuration/Setting.vue'),
                    props: true
                },
                {
                    path: '/configuration/stepwizard/uid/:id/:accountid?',
                    name: 'StepWizard',
                    component: () =>
                        import ('./views/configuration/StepWizard.vue')
                },
                {
                    path: '/consumer/configuration/suppression-list/uid/:id/:accountid?',
                    name: 'consumer-configuration-suppression-list',
                    component: () =>
                        import ('./views/consumer/configuration/SuppressionList.vue')
                },
                {
                    path: '/consumer/configuration/known-customers/uid/:id/:accountid?',
                    name: 'consumer-configuration-known-customers',
                    component: () =>
                        import ('./views/consumer/configuration/KnownCustomers.vue')
                },
                // Workflow
                {
                    path: '/workflow/leadhandlingworkflow/uid/:id/:accountid?',
                    name: 'LeadHandlingWorkflow',
                    component: () =>
                        import ('./views/workflow/LeadHandlingWorkflow.vue')
                },
                // Reseller
                {
                    path: '/reseller/customers/uid/:id/:accountid?',
                    name: 'Customers',
                    component: () =>
                        import ('./views/reseller/Customers.vue')
                },
                {
                    path: '/reseller/reseller-setting/uid/:id/:accountid?',
                    name: 'Reseller Setting',
                    component: () =>
                        import ('./views/reseller/ResellerSetting.vue')
                },
                {
                    path: '/reseller/reseller-manage/uid/:id/:accountid?',
                    name: 'Reseller Manage',
                    component: () =>
                        import ('./views/reseller/ResellerManage.vue')
                },
                {
                    path: '/reseller/reseller-report/uid/:id/:accountid?',
                    name: 'Reseller Report',
                    component: () =>
                        import ('./views/reseller/ResellerReport.vue')
                },
                {
                    path: '/reseller/customer-activity-report/uid/:id/:accountid?',
                    name: 'Customer Activity Report',
                    component: () =>
                        import ('./views/reseller/CustomerActivityReport.vue')
                },
                {
                    path: '/reseller/customer-inactive-report/uid/:id/:accountid?',
                    name: 'Customer Inactive Report',
                    component: () =>
                        import ('./views/reseller/CustomerInactiveReport.vue')
                },
                {
                    path: '/reseller/consumer-customer-activity-report/uid/:id/:accountid?',
                    name: 'Consumer Customer Activity Report',
                    component: () =>
                        import ('./views/reseller/ConsumerCustomerActivityReport.vue')
                },
                {
                    path: '/reseller/consumer-reseller-detail-report/uid/:id/:accountid?',
                    name: 'Consumer Reseller Detail Report',
                    component: () =>
                        import ('./views/reseller/ConsumerResellerDetailReport.vue')
                },
                {
                    path: '/reseller/consumer-reseller-summary-report/uid/:id/:accountid?',
                    name: 'Consumer Reseller Summary Report',
                    component: () =>
                        import ('./views/reseller/ConsumerResellerSummaryReport.vue')
                },
                {
                    path: '/reseller/reseller-billing-map/uid/:id/:accountid?',
                    name: 'Reseller Billing Map',
                    component: () =>
                        import ('./views/reseller/ResellerBillingMap.vue')
                },

                // Need Help
                {
                    path: '/need-help/general-use/uid/:id/:accountid?',
                    name: 'need-help',
                    component: () =>
                        import ('./views/need-help/GeneralUse.vue')
                },

                // Sign Up
                {
                    path: '/sign-up/uid/:id/:accountid?',
                    name: 'sign-up',
                    component: () =>
                        import ('./views/SignUp.vue')
                },

                // Sign Up
                {
                    path: '/sign-up-customer/uid/:id/:accountid?',
                    name: 'sign-up-customer',
                    component: () =>
                        import ('./views/SignUpCustomer.vue')
                },

                //Others
                {
                    path: '/view-report/uid/:id/:accountid?/:reportname',
                    name: 'view-report',
                    component: () =>
                        import ('./components/ReportViewer.vue')
                },
                {
                    path: '/lead-report/uid/:id/:accountid?/LeadVisitRawDataReport',
                    name: 'view-report2',
                    component: () =>
                        import ('./views/leads/visitRawDataExport.vue')
                },

                // Create Dashboard
                // {
                //   path: '/create-dashboard/gmb-insight/uid/:id',
                //   name: 'GMB Insight',
                //   component: () => import('./views/create-dashboard/GmbInsight.vue')
                // },
                // {
                //   path: '/create-dashboard/ppc-adwords/uid/:id',
                //   name: 'PPC Adwords',
                //   component: () => import('./views/create-dashboard/PPCAdwords.vue')
                // },
                // {
                //   path: '/create-dashboard/facebook-organic/uid/:id',
                //   name: 'Facebook Organic',
                //   component: () => import('./views/create-dashboard/FacebookOrganic.vue')
                // },
                // {
                //   path: '/create-dashboard/facebook-paid/uid/:id',
                //   name: 'Facebook Paid',
                //   component: () => import('./views/create-dashboard/FacebookPaid.vue')
                // },
                // {
                //   path: '/create-dashboard/seo/uid/:id',
                //   name: 'Seo',
                //   component: () => import('./views/create-dashboard/Seo.vue')
                // },
                // {
                //   path: '/create-dashboard/programmatic/uid/:id',
                //   name: 'programmatic',
                //   component: () => import('./views/create-dashboard/Programmatic.vue')
                // },
                // {
                //   path: '/create-dashboard/ppcbing/uid/:id',
                //   name: 'PPC-Bing',
                //   component: () => import('./views/create-dashboard/PPCBing.vue')
                // },
                // {
                //   path: '/create-dashboard/markup/uid/:id',
                //   name: 'Markup',
                //   component: () => import('./views/create-dashboard/Markup.vue')
                // },
                // {
                //   path: '/create-dashboard/dashboard-your-connection/uid/:id',
                //   name: 'dashboard-your-connection',
                //   component: () => import('./views/create-dashboard/DashboardYourConnection.vue')
                // },

                // Social Automation
                // {
                //   path: '/social-automation/publishing/uid/:id',
                //   name: 'publishing',
                //   component: () => import('./views/social-automation/Publishing.vue')
                // },
                // {
                //   path: '/social-automation/monitoring/uid/:id',
                //   name: 'monitoring',
                //   component: () => import('./views/social-automation/Monitoring.vue')
                // },
                // {
                //   path: '/social-automation/settings/uid/:id',
                //   name: 'settings',
                //   component: () => import('./views/social-automation/Settings.vue')
                // },

                /* GMB Management */
                // {
                //   path: '/gmb-management/local-ranking/uid/:id',
                //   name: 'local-ranking',
                //   component: () => import('./views/gmb-management/LocalRanking.vue')
                // },

                //E-Commerce
                {
                    path: '/ecommerce/dashboard/uid/:id/:accountid?',
                    name: 'ecommerce-dashboard',
                    component: () =>
                        import ('./views/ecommerce/Dashboard.vue')
                },
                {
                    path: '/ecommerce/search-carts/uid/:id/:accountid?',
                    name: 'ecommerce-search-carts',
                    component: () =>
                        import ('./views/ecommerce/SearchCarts.vue')
                },
                // Demo
                {
                    path: '/demo/uid/:id/:accountid?',
                    name: 'demo',
                    component: () =>
                        import ('./views/demo.vue')
                },

                // {
                //   path: '/view-report/uid/:id',
                //   name: 'view-report',
                //   component: () => import('./components/ReportViewer.vue')
                // },

                {
                    path: '/lead-report/uid/:id/:accountid?/LeadVisitContactExport',
                    name: 'contact-export',
                    component: () =>
                        import ('./views/leads/visitContactData.vue')
                },

            ],
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () =>
                import ('@/layouts/full-page/FullPage.vue'),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: '/pages/login',
                    name: 'page-login',
                    component: () =>
                        import ('@/views/pages/Login.vue')
                },
                {
                    path: '/pages/two-factor-auth',
                    name: 'page-two-factor-auth',
                    component: () =>
                        import ('@/views/pages/LoginTwoFactorAuth.vue')
                },
                {
                    path: '/pages/login2',
                    name: 'page-login2',
                    component: () =>
                        import ('@/views/pages/Login2.vue')
                },
                {
                    path: '/pages/login3',
                    name: 'page-login3',
                    component: () =>
                        import ('@/views/pages/Login3.vue')
                },
                {
                    path: '/pages/login4',
                    name: 'page-login4',
                    component: () =>
                        import ('@/views/pages/Login4.vue')
                },
                {
                    path: '/pages/login5',
                    name: 'page-login5',
                    component: () =>
                        import ('@/views/pages/Login5.vue')
                },
                /* Thank You */
                {
                    path: '/pages/thankyou/uid/:id/:accountid?',
                    name: 'page-thankyou',
                    component: () =>
                        import ('@/views/pages/ThankYou.vue')
                },
                {
                    path: '/pages/Forgotpassword',
                    name: 'page-Forgotpassword',
                    component: () =>
                        import ('@/views/pages/Forgotpassword.vue')
                },
                {
                    path: '/pages/ForgotpasswordR',
                    name: 'page-ForgotpasswordReseller',
                    component: () =>
                        import ('@/views/pages/ForgotpasswordReseller.vue')
                },
                {
                    path: '/pages/reset-password',
                    name: 'reset-password',
                    component: () =>
                        import ('@/views/pages/ResetPassword.vue')
                },
                {
                    path: '/pages/error-404',
                    name: 'page-error-404',
                    component: () =>
                        import ('@/views/pages/Error404.vue')
                },
                {
                  path: '/pages/ForgotpasswordC',
                  name: 'page-customer-forgot-password',
                  component: () =>
                      import ('@/views/pages/CustomerForgotPassword.vue')
                },
                // {
                //   path: '/pages/customer/login',
                //   name: 'page-customer-login',
                //   component: () =>
                //       import ('@/views/pages/CustomerLogin.vue')
                // },
                // {
                //   path: '/pages/customer/forgot-password',
                //   name: 'page-customer-forgot-password',
                //   component: () =>
                //       import ('@/views/pages/CustomerForgotPassword.vue')
                // },
                // {
                //   path: '/pages/customer/reset-password',
                //   name: 'page-customer-reset-password',
                //   component: () =>
                //       import ('@/views/pages/CustomerResetPassword.vue')
                // },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

const checkToken = () => {
    if (localStorage.getItem('VVtoken')) {
        return true;
    } else {
        return false
    }
}

router.beforeEach((to, from, next) => {
    // if(!localStorage.getItem("VendastaVVAccountIdTest")){
    //     localStorage.setItem("VendastaVVAccountIdTest", JSON.stringify(window.location.href));
    // }
    if (to.name !== 'page-login' && to.name !== 'page-login2' && to.name !== 'page-login3' && to.name !== 'page-login4' && to.name !== 'page-login5' && to.name !== 'page-thankyou' && to.name !== 'page-Forgotpassword' && to.name !== 'page-ForgotpasswordReseller' && to.name !== 'reset-password' && to.name !== 'page-two-factor-auth' && to.name !== 'page-error-404' && to.name !== 'page-customer-login' && to.name !== 'page-customer-forgot-password' && to.name !== 'page-customer-reset-password') {
        if (checkToken()) {
            next()
        } else {
            //next()
            next({
                name: 'page-login',
                params: { reload: true }
            })
        }
    }
    next()
})

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router
